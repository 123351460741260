<template>
	<el-container>
		<el-header>
			<router-link to="/">
				<el-image :src="logoUrl" v-if="logoUrl" fit="fit"></el-image>
				<el-image :src="logo" v-else fit="fit"></el-image>
			</router-link>
		</el-header>
		<el-main>
			<el-image :src="require('../assets/images/login_bg.jpg')" fit="fit"></el-image>
			<div class="whiteBg">
				<div class="title">
					<div class="item" :class="current === index ? 'on' : ''" v-for="(item, index) in navList" @click="navTap(index)" :key="index">{{ item }}</div>
				</div>
				<div class="list" :hidden="current !== 0">
					<form @submit.prevent="submit">
						<div class="item">
							<i class="el-icon-mobile"></i>
							<input type="text" placeholder="输入手机号码" v-model="account" required />
						</div>
						<div class="item">
							<i class="el-icon-lock"></i>
							<input type="password" placeholder="填写登录密码" v-on:keyup.enter="submit" v-model="password" required />
						</div>
					</form>
					<div class="forgetPwd" @click="$router.push({ name: 'RetrievePassword' })">
						<i class="el-icon-warning-outline"></i>
						忘记密码
					</div>
				</div>
				<div class="list" :hidden="current !== 1">
					<div class="item">
						<i class="el-icon-mobile"></i>
						<input type="text" placeholder="输入手机号码" v-model="account" />
					</div>
					<div class="item">
						<i class="el-icon-lock"></i>
						<div class="align-left">
							<input type="text" placeholder="填写验证码" class="codeIput" v-model="captcha" v-on:keyup.enter="loginMobile" />
							<button class="code" :disabled="disabled" :class="disabled === true ? 'on' : ''" @click="code">{{ text }}</button>
						</div>
					</div>
				</div>
				<div class="logon" @click="loginMobile" :hidden="current !== 1">登录</div>
				<div class="logon" @click="submit" :hidden="current === 1">登录</div>
				<div class="tip">
					没有账号?
					<router-link to="/register">立即注册</router-link>
				</div>
			</div>
		</el-main>
		<el-footer>
			<div>
				版权所有 {{$config.companyName}}
				<span>渝ICP备20006289-1号</span>
				{{$config.appName}}全球汇
			</div>
			<div>Copyright © 2015 - 2024 buyersvip.com All Rights Reserved</div>
		</el-footer>
	</el-container>
</template>
<script>
	import sendVerifyCode from '../mixins/SendVerifyCode';
	import {
		login,
		loginMobile,
		registerVerify,
		getCodeApi
	} from '../api/user';
	import {
		validatorDefaultCatch
	} from '../utils/dialog';
	import attrs, {
		required,
		alpha_num,
		chs_phone
	} from '../utils/validate';

	export default {
		name: 'Login',
		mixins: [sendVerifyCode],
		data: function() {
			return {
				navList: ['账号登录', '快速登录'],
				current: 1,
				account: '',
				password: '',
				captcha: '',
				type: 'login',
				logoUrl: '',
				keyCode: '',
				logo: require('@/assets/images/logo_' + this.$config.appvesion + '.png'),
			};
		},
		mounted: function() {
			this.getCode();
		},
		methods: {
			navTap: function(index) {
				this.current = index;
			},
			getCode() {
				getCodeApi()
					.then(res => {
						this.keyCode = res.data.key;
					})
					.catch(res => {
						this.$dialog.error(res.msg);
					});
			},
			async loginMobile() {
				var that = this;
				const {
					account,
					captcha
				} = that;
				try {
					await that
						.$validator({
							account: [required(required.message('手机号码')), chs_phone(chs_phone.message())],
							captcha: [required(required.message('验证码')), alpha_num(alpha_num.message('验证码'))]
						})
						.validate({
							account,
							captcha
						});
				} catch (e) {
					return validatorDefaultCatch(e);
				}
				loginMobile({
						phone: that.account,
						captcha: that.captcha,
						// spread: cookie.get('spread'),
						spread: window.sessionStorage.getItem('spread') || 0
					})
					.then(res => {
						let data = res.data;
						window.sessionStorage.setItem('token', data.token)
						this.$router.push('/')
					})
					.catch(res => {
						that.$dialog.error(res.msg);
					});
			},
			async code() {
				var that = this;
				const {
					account
				} = that;
				try {
					await that
						.$validator({
							account: [required(required.message('手机号码')), chs_phone(chs_phone.message())]
						})
						.validate({
							account
						});
				} catch (e) {
					return validatorDefaultCatch(e);
				}
				await registerVerify({
						phone: that.account,
						type: that.type,
						key: that.keyCode
					})
					.then(res => {
						that.$dialog.success(res.msg);
						that.sendCode();
					})
					.catch(res => {
						that.$dialog.error(res.msg);
					});
			},
			async submit() {
				const {
					account,
					password
				} = this;
				try {
					await this.$validator({
						account: [required(required.message('账号')), attrs.range([5, 16], attrs.range.message('账号')), alpha_num(
							alpha_num.message('账号'))],
						password: [required(required.message('密码')), attrs.range([6, 16], attrs.range.message('密码')), alpha_num(
							alpha_num.message('密码'))]
					}).validate({
						account,
						password
					});
				} catch (e) {
					return validatorDefaultCatch(e);
				}
				login({
						account,
						password
					})
					.then(({
						data
					}) => {
						window.sessionStorage.setItem('token', data.token)
						this.$router.push('/')
					})
					.catch(e => {
						this.$dialog.error(e.msg);
					});
			}
		}
	};
</script>
<style lang="less" scoped>
	.el-header {
		text-align: left;
		height: auto !important;
		margin: 16px 0 16px 342px;
	}

	.el-footer {
		color: #333;
		text-align: center;
		line-height: 36px;
		padding: 20px 0;
		height: auto !important;
		font-size: 14px;

		span {
			color: #f60;
		}
	}

	.el-main {
		text-align: center;
		padding: 0;
		position: relative;

		.el-image {
			width: 100%;
		}

		.whiteBg {
			position: absolute;
			transform: translate(-50%, -50%);
			right: 10%;
			top: 50%;
			width: 340px;
			border-radius: 12px;
			background-color: #fff;
			padding: 20px;

			.title {
				font-size: 18px;
				color: #282828;
				text-align: center;
				font-weight: bold;
				display: flex;
				justify-content: space-around;

				.item {
					color: #999999;
					border-bottom: 2px solid #fff;
					padding-bottom: 6px;
					cursor: pointer;
				}

				.item.on {
					color: #282828;
					border-bottom-color: #f35749;
				}
			}

			.list {
				.forgetPwd {
					text-align: right;
					font-size: 12px;
					color: #666;
					margin-top: 10px;
				}

				.item {
					border-bottom: 1px solid #ededed;
					padding: 30px 0 10px 0;
					display: flex;
					align-items: center;

					i {
						font-size: 20px;
						padding-right: 10px;
					}

					input {
						flex: 1;
						border: none;
						outline: none;
						font-size: 16px;
						line-height: 32px;
					}

					.align-left {
						flex: 1;
						display: flex;
						align-items: center;

						.code {
							background-color: #f35446;
							border-radius: 30px;
							color: #fff;
							line-height: 32px;
							text-align: center;
							border: none;
							outline: none;
							padding: 0 16px;
						}
					}
				}
			}

			.logon {
				font-size: 16px;
				color: #fff;
				font-weight: bold;
				height: 36px;
				border-radius: 30px;
				background: linear-gradient(to right, #f35447 0%, #ff8e3c 100%);
				background: -webkit-linear-gradient(to right, #f35447 0%, #ff8e3c 100%);
				background: -moz-linear-gradient(to right, #f35447 0%, #ff8e3c 100%);
				text-align: center;
				line-height: 36px;
				margin: 32px 0 12px 0;
				cursor: pointer;
			}

			.tip {
				text-align: center;
				font-size: 14px;
				color: #cccccc;

				a {
					color: #fc4141;
					text-decoration: none;
				}
			}
		}
	}
</style>
